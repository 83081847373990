/* eslint-disable */
import Vue from "vue";
// let apiUrl = "http://www.laowubang.com";
// 首页的广告轮播图
export function getSwipers() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/banners`).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    })
  })
}
// 获取新闻列表
export function getNews({commit}, url) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(url || `/api/news`).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    })
  })
}

// 新闻详情
export function getNewsDetail({ commit }, id) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/news/${id}`).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    })
  })
}

// 获取合作伙伴列表
export function getPartners() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/partners`).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    })
  })
}

// 获取友情链接
export function getLinks() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/links`).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    })
  })
}
// 获取城市列表
export function getCity() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/areas`).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    })
  })
}
// 获取门店列表
export function getStores({commit}, url) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(url || `/api/stores`).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    })
  })
}
// 获取具体门店
export function getStoresById({commit}, id) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/stores?${id}`).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    })
  })
}
// 获取门店详情
export function getStoreDetail({commit}, id) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/stores/${id}`).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    })
  })
}
// 就业顾问
export function getConsultants({commit}, id) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/stores/${id}/consultants`).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    })
  })
}
// 招聘贤士
export function getRecruits() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/recruits`).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    })
  })
}

// 员工秀
export function getStaffs() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/staffs`).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    })
  })
}

// 年会秀(swiper)
export function getWonderful() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/shows/wonderful`).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    })
  })
}


// 活动秀
export function getActivitys() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/shows`).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    })
  })
}

// 加入我们
export function join({ commit }, params) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.post(`/api/users`, params).then(response => {
      if (response.status === 200) {
        resolve("success")
      }
    })
  })
}

// 大事件
export function getTimeLines() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/timelines`).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    })
  })
}
//
// // 设置
// export function getSettings({commit}) {
//   return new Promise((resolve, reject) => {
//     Vue.prototype.$http.get(`/api/settings`).then(response => {
//       if (response.status === 200) {
//         // 设置缓存
//         commit('setSettings', response.data);
//         resolve(response.data)
//       }
//     })
//   })
// }
