/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store.js";
// import './libs/rem.js';
// import { Carousel, CarouselItem, Loading, Button, Input, Alert, MessageBox, Notification, Row, Col, Menu, MenuItem, Pagination, Dialog,Breadcrumb,BreadcrumbItem } from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
/* iconfont的symbol引入 */
import "./assets/Iconfont/iconfont.js";
// import { Row } from "element-ui";
 Vue.use(ElementUI)
// Vue.use(Input);
// Vue.use(Button);
// Vue.use(Carousel);
// Vue.use(CarouselItem);
// Vue.use(Loading.directive);
// Vue.use(Alert);
// Vue.use(Row);
// Vue.use(Col);
// Vue.use(Menu);
// Vue.use(MenuItem);
// Vue.use(Pagination);
// Vue.use(Dialog);
// Vue.use(Breadcrumb);
// Vue.use(BreadcrumbItem)
// Vue
// Vue.prototype.$loading = Loading.service;
// Vue.prototype.$alert = MessageBox.alert;
// Vue.prototype.$notify = Notification;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount("#app");
