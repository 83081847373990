import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes: [
    // 首页
    {
      path: '/',
      name: 'Home',
      component (resolve) {
        require(['./views/Home.vue'], resolve)
      }
    },
    // {
    //   path: '/application',
    //   name: 'Application',
    //   component (resolve) {
    //     require(['./views/Job/Application.vue'], resolve)
    //   }
    // },
    // {
    //   path: '/storedetail/:id',
    //   name: 'StoreDetail',
    //   component (resolve) {
    //     require(['./views/Job/StoreDetail.vue'], resolve)
    //   }
    // },
    // 关于我们
    {
      path: '/aboutUs',
      name: 'AboutUs',
      component (resolve) {
        require(['./views/AboutUs.vue'], resolve)
      }
    },
    {
      path: '/culture',
      name: 'Culture',
      component (resolve) {
        require(['./views/Culture.vue'], resolve)
      }
    },
    // 产品方案
    {
      path: '/product',
      name: 'Product',
      component (resolve) {
        require(['./views/Product.vue'], resolve)
      }
    },
    // 企业风采
    {
      path: '/style',
      name: 'Style',
      component (resolve) {
        require(['./views/Style.vue'], resolve)
      }
    },
    // 新闻资讯
    {
      path: '/news',
      component (resolve) {
        require(['./views/News.vue'], resolve)
      },
      children: [
        {
          path: '',
          name: 'News',
          component (resolve) {
            require(['./views/News/Home.vue'], resolve)
          }
        },
        {
          path: ':id',
          name: 'NewsDetail',
          component (resolve) {
            require(['./views/News/Detail.vue'], resolve)
          }
        }
      ]
    },
    // 联系我们
    {
      path: '/contactUs',
      name: 'ContactUs',
      component (resolve) {
        require(['./views/ContactUs.vue'], resolve)
      }
    },
    // 加盟我们
    {
      path: '/joinUs',
      name: 'JoinUs',
      component (resolve) {
        require(['./views/JoinUs.vue'], resolve)
      }
    },
    {
      path: '*',
      component (resolve) {
        require(['./views/Home.vue'], resolve)
      }
    }
  ]
})
